import $ from 'jquery';

class PreloadContent {
	constructor(irApi, session, gaHandler) {
		this._api = irApi;
		this._preloadContent = {};
		this._rootUrl = `${window.location.protocol}//${window.location.host}`
		this._session = session;
		this._gaHandler = gaHandler;
		this.initialize();
	}

	initialize() {
		this._api.getPreloadContent(window.pageInitialization.initialUrl.slug)
			.then((preload) => {
				$.extend(this._preloadContent, preload);
				const name = preload?.Name || document.title;
				this._doRecordPageViewWhenReady(name);
				document.title = name;
			});
	}

	getNormalizedLocation() {
		const content = this._preloadContent;
		if (!content) { return null; }

		if (content.ProjectGuid) {
			let location = `${this._rootUrl}/view/project/${content.ProjectGuid}`;
			if (content.CampaignGuid) {
				location += `/campaign/${content.CampaignGuid}`;
			}
			return location;
		} else if (content.ListingId) {
			return `${this._rootUrl}/view/listing/${content.ListingId}`;
		} else {
			return null;
		}
	}

	get content() {
		return this._preloadContent;
	}

	_doRecordPageView(name) {
		let location = this.getNormalizedLocation();
		if (this._session.isInternal) {
			location = `${location}/internal`;
			name = `${name} (internal traffic)`;
		} else {
			const rights = this._session.getRights(this._preloadContent?.ProjectId);
			if (rights.length > 0) {
				location = `${location}/agent`
				name = `${name} (agent traffic)`;
			}
		}
		return this._gaHandler.recordPageView(name, location);
	}

	_doRecordPageViewWhenReady(name) {
		this._session.onIdentityLoaded(() => this._doRecordPageView(name));
		if (this._session.isIdentified) {
			this._doRecordPageView(name);
		}
	}
}

export { PreloadContent }
