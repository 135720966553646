/**
 * @file Encapsulation of recording pertinent user/session activities.
 */
import { IrApi } from "../api/api.es6.js"
import { IrUtils } from "../utils/utils.es13.js"

const ACTION_NAMES = {
	aiInteracted: 'ai_interacted',
	leadCaptured: 'lead_captured'
};

/**
 * @typedef Activity
 * @type {object}
 * @property {string} actionName - chatroomaction name
 * @property {string?} notificationName - notification name
 * @property {()=>string} getMessage - returns the "message" string
 * @property {()=>boolean} blockIf - if this returns true, activity queue processing will be stopped (waiting for other events to happen before proceeding)
 * @property {()=>boolean} discardIf - if this function returns true, discard the activity (don't send it)
 * @property {()=>boolean} afterExecute - function called after the activity has been recorded (send to server)
 * @property {ActivityOptions?} options - options specific to a listing/contentitem used in ChatRoomActions (but not notifications)
 * @property {Date?} timestamp
 */

/**
* @typedef ActivityOptions
* @type {object}
* @property {string} clientInstanceId
* @property {string?} contentId
* @property {string?} contentName
* @property {number?} contentNumericId
* @property {string?} contentType
* @property {string?} senderEmail
* @property {string?} senderName
* @property {string?} senderPhone
* @property {string?} userAuthToken
* @property {string?} userAvatarPhotoUrl
* @property {string?} userDisplayName
* @property {number?} userIdentityId
* @property {string?} userLogin
*/

/**
 * Track user/session activity.
 * Responds to events to the system and generates a server side timeline of user/session actions.
 * The "joinRoom" event must be first in the list, and will wait for the content to be loaded and the user to be identified.
 * Prevents duplicate events from being recorded (multiple joinCalls/leaveCalls).
 * @class
 */
class ActivityMonitor {
	/**
	 * @param {object} options
	 * @param {ContentModel} options.contentModel
	 * @param {string} options.infinityyApiUrl
	 * @param {number} options.roomId
	 * @param {IrSession} options.session
	 * @param {object} options.settings
	 * @param {UserModel} options.userModel
	 */
	constructor(options) {
		this._api = new IrApi(options.infinityyApiUrl);
		this._callConnected = false;
		this._contentModel = options.contentModel;
		this._latestTimestamp = new Date();
		this._leadModel = options.leadModel;
		this._pageClosing = false;
		this._queue = [];
		this._recordedJoinSession = false;
		this._recordedLeaveSession = false;
		this._recordedLeadCapture = false;
		this._roomId = options.roomId;
		this._session = options.session;
		this._settings = options.settings;
		this._userModel = options.userModel;
		this._viewerModel = options.viewerModel;
	}

	aiInteracted() {
		return this._processActivity({
			actionName: ACTION_NAMES.aiInteracted,
			options: {
				chatRoomAction: false
			}
		});
	}

	clickCallToAction(ctaName) {
		return this._processActivity({
			actionName: 'callToAction',
			getMessage: () => { return `${this._userModel.displayName} clicked ${ctaName}` },
			options: {
				ctaName: ctaName
			}
		});
	}

	closePage() {
		this._pageClosing = true;
		return this.leaveRoom();
	}

	contactFormShown() {
		return this._processActivity({
			actionName: 'contact_form_shown'
		});
	}

	contactFormDataSaved() {
		return this._processJoinRoomIfNecessary()._processActivity({
			actionName: 'contact_form_data_saved'
		});
	}

	contactFormDismissed() {
		return this._processJoinRoomIfNecessary()._processActivity({
			actionName: 'contact_form_dismissed'
		});
	}

	contentChanged(contentPath, contentItem) {
		if (!contentPath) {
			return null;
		}
		let message = '';
		const options = {
			contentPath: contentPath
		}
		if (contentItem) {
			$.extend(options, {
				contentId: contentItem.contentReferenceId,
				contentName: contentItem.name,
				contentNumericId: contentItem.contentReferenceId,
				contentType: 'listingContentItem'
			});
			message = `${this._userModel.displayName} viewed content ${contentItem.name}`;
		}

		return this._processJoinRoomIfNecessary()._processActivity({
			actionName: 'viewContent',
			getMessage: () => { return message },
			options: options
		});
	}

	leadCaptured() {
		return this._processJoinRoomIfNecessary()._processActivity({
			actionName: ACTION_NAMES.leadCaptured,
			getMessage: () => { return `${this._userModel.displayName} captured as lead` },
			discardIf: () => { return this._recordedLeadCapture; },
			afterExecute: () => { this._recordedLeadCapture = true; }
		})
	}

	splashAiPromptSelect(prompt) {
		return this._processJoinRoomIfNecessary()._processActivity({
			actionName: 'splash_ai_prompt_select',
			getMessage: () => { return `${this._userModel.displayName} selected "${prompt}" in ai splash modal.` },
		})
	}

	splashAiCloseDontShow() {
		return this._processJoinRoomIfNecessary()._processActivity({
			actionName: 'splash_ai_close_dont_show',
			getMessage: () => { return `${this._userModel.displayName} closed ai splash modal, with "don't show again" selected.` },
		})
	}

	contentVisible() {
		return this._processJoinRoomIfNecessary()._processActivity({
			actionName: 'initialContentVisible',
			getMessage: () => { return `${this._userModel.displayName} loaded initial content ${this._contentModel.getContentName()}`; },
			discardIf: () => { return this._contentVisible; },
			afterExecute: () => { this._contentVisible = true; }
		});
	}

	joinCall() {
		return this._processActivity({
			actionName: 'joinVoice', notificationName: 'JoinVoice',
			getMessage: () => { return `${this._userModel.displayName} joined a call for content ${this._contentModel.getContentName()}`; },
			discardIf: () => { return this._callConnected; },
			afterExecute: () => { this._callConnected = true; }
		});
	}

	leaveCall() {
		return this._processActivity({
			actionName: 'leaveVoice', notificationName: 'LeaveVoice',
			getMessage: () => { return `${this._userModel.displayName} left a voice call for content ${this._contentModel.getContentName()}`; },
			discardIf: () => { return !this._callConnected; },
			afterExecute: () => { this._callConnected = false; }
		});
	}

	leaveRoom() {
		return this.leaveCall()._processActivity({
			actionName: 'leaveRoom', notificationName: 'LeaveSession',
			getMessage: () => { return `${this._userModel.displayName} left a session for content ${this._contentModel.getContentName()}`; },
			discardIf: () => { return !this._recordedLeaveSession; },
			afterExecute: () => { this._recordedLeaveSession = true; }
		});
	}

	loadContent() {
		return this._processJoinRoomIfNecessary().selectListing();
	}

	requestedAccess() {
		const ci = this._contentModel.contentId;
		if (!ci) { return this; }

		return this._processActivity({
			directMessageCode: 'RequestAccess',
			getMessage: () => { return `The user has requested documents for ${this._contentModel.getContentName()}`; }
		});
	}

	userInteracted(eventType, eventTarget) {
		return this._processJoinRoomIfNecessary()._processActivity({
			actionName: 'user_interacted',
			options: {
				eventTarget: eventTarget,
				eventType: eventType
			}
		});
	}

	/**
	 * Record that a chat message has been sent.
	 * Notify the appropriate parties.
	 * @param {string} message
	 * @returns
	 */
	sendChatMessage(message) {
		const ci = this._contentModel.contentId;
		if (!ci) { return this; }

		return this._processActivity({
			actionName: 'chatMessage', messageNotification: true, notificationName: 'AddChatMessage',
			getMessage: () => { return `${this._getUserContactInfo()} added a message to chat for content ${this._contentModel.getContentName()}`; },
			options: {
				message: message
			}
		});
	}

	selectListing() {
		const listing = this._contentModel.getSelectedListing();
		if (!listing) { return this; }

		return this._processActivity({
			actionName: 'viewListing', notificationName: 'ViewListing',
			getMessage: () => { return `${this._userModel.displayName} viewed ${listing.Name}`; },
			options: {
				contentId: listing.Id,
				contentName: listing.Name,
				contentNumericId: listing.Id,
				contentType: 'listing'
			}
		});
	}

	updateUser() {
		return this._processJoinRoomIfNecessary();
	}

	/**
	 * The JSON object that ends up in ChatRoomActions.
	 * @param {string} message
	 * @param {ActivityOptions} options
	 * @returns {object} JSON object going into ChatRoomActions.ActionJson
	 */
	_getActionObject(message, options) {
		return IrUtils.shallowStripNullies({
			ClientInstanceId: window.clientInstanceId,
			ContentId: options.contentId,
			ContentName: options.contentName,
			ContentType: options.contentType,
			ContentPath: options.contentPath,
			Message: message,
			NumericContentId: options.contentNumericId,
			UserProfileImgUrl: options.userAvatarPhotoUrl
		});
	}

	/**
	 * Get a "flat" set of options for an activity. Flat so there basic types and not references.
	 * This takes a snapshot of the current activity-related variables from the current ContentModel/UserModel.
	 * @returns {ActivityOptions}
	 */
	_getActivityOptions() {
		return IrUtils.shallowStripNullies({
			clientInstanceId: window.clientInstanceId,
			contentId: this._contentModel.contentId?.id,
			contentName: this._contentModel.getContentName(),
			contentNumericId: this._contentModel.content?.Id,
			contentType: this._contentModel.contentId?.type,
			roomId: this._roomId,
			senderEmail: this._leadModel.leadContact?.email,
			senderName: this._leadModel.leadContact?.name || this._userModel.displayName,
			senderPhone: this._leadModel.leadContact?.phone,
			userAuthToken: this._session.authToken,
			userAvatarPhotoUrl: this._userModel.avatarPhotoUrl,
			userDisplayName: this._userModel.displayName,
			userIdentityId: this._session.identityId,
			userLogin: this._userModel.login
		});
	}

	_getApiV4RequestContext() {
		let requestContext = {
			clientInstanceId: window.clientInstanceId,
			clientToken: this._session.clientTokenV4,
			roomId: this._roomId,
		};
		return IrUtils.deepStripNullies(requestContext);
	}

	_getUserContactInfo() {
		let nameAndInfo = this._leadModel?.leadContact?.name || this._userModel.displayName;
		if (this._leadModel?.leadContact?.phone) {
			nameAndInfo += ' phone:' + this._leadModel?.leadContact?.phone;
		}
		if (this._leadModel?.leadContact?.email) {
			nameAndInfo += ' email:' + this._leadModel?.leadContact?.email;
		}
		return nameAndInfo;
	}

	/**
	 * Get all of the parameters needed to record a visit that generates notifications
	 * @returns {Visit}
	 */
	_getVisit() {
		return IrUtils.shallowStripNullies({
			ChatId: this._roomId,
			ClientInstanceId: window.clientInstanceId,
			ContentId: this._contentModel.contentId?.id,
			ContentType: this._contentModel.contentId?.type,
			Domain: window.location.hostname,
			NotificationType: 'userContentVisit',
			ParticipantId: this._userModel.participantId,
			SessionId: `chat${this._roomId}`,
			TelemetryChannel: window.telemetryRoomId,
			VisitorName: this._userModel.displayName
		});
	}

	/**
	 * Prepare to record an activity, but queue to be recorded later if joinRoom hasn't been sent yet.
	 * @param {Activity} activity
	 * @returns {ActivityMonitor}
	 */
	_processActivity(activity) {
		activity.timestamp = new Date();
		this._queue.push(activity);
		return this._processQueue();
	}

	/**
	 * Create the joinRoom action (if one hasn't already been recorded).
	 * Queue it (head of queue).
	 * Execute it (and all subsequent activities), if content has been loaded and the user has been identified.
	 * @returns {ActivityMonitor}
	 */
	_processJoinRoomIfNecessary() {
		if (this._recordedJoinSession) { return this; }

		const activity = {
			actionName: 'joinRoom', notificationName: 'JoinSession',
			getMessage: () => { return `${this._userModel.displayName} visited room: ${this._contentModel.getContentName()}`; },
			afterExecute: () => { this._recordedJoinSession = true; },
			blockIf: () => {
				return !this._contentModel.getContentName()
					|| (this._contentModel.isSplashScreenEnabled && !this._leadModel.leadContact?.email)
					|| !this._userModel.displayName
			},
			discardIf: () => { return this._recordedJoinSession; },
			isVisit: true,
			timestamp: new Date(),
			triggerNotification: true
		};
		this._queue.unshift(activity); // joinRoom goes to the front.
		return this._processQueue();
	}

	/**
	 * Step through the queue, processing activities
	 * @returns {ActivityMonitor}
	 */
	_processQueue() {
		if (!this._recordedJoinSession) {
			const firstActivity = this._queue[0];
			if (firstActivity?.actionName !== 'joinRoom') { return this; }
		}

		while (this._queue.length > 0) {
			const activity = this._queue[0];

			// Block processing?
			if (activity.blockIf && activity.blockIf()) { return this; }

			if (!activity.discardIf || !activity.discardIf()) {
				if (activity.timestamp < this._latestTimestamp) {
					activity.timestamp = this._latestTimestamp;
				} else {
					this._latestTimestamp = activity.timestamp;
				}
				this._sendActivity(activity);
				activity.afterExecute && activity.afterExecute();
			}
			this._queue.shift();
		}
		return this;
	}

	/**
	 * Send activity to server (chatroomaction and notification).
	 * @param {Activity} activity
	 * @returns {ActivityMonitor}
	 */
	_sendActivity(activity) {
		const message = activity.getMessage ? activity.getMessage() : '';
		const options = this._getActivityOptions();
		const actionOptions = $.extend({}, options, activity.options);
		let notificationHandled = false;

		if (activity.actionName) {
			if ([ACTION_NAMES.aiInteracted, ACTION_NAMES.leadCaptured].includes(activity.actionName)) {
				// New-style activity recording with some logic happening on the server-side.
				this._api.sendGoogleAnalyticsEvent(activity.actionName);
				this._api.sendAddActivity(activity.actionName, this._viewerModel.projectGuid,
					this._getApiV4RequestContext()).then((result) => {
						const ga4EventName = result?.result?.recordGa4Event;
						if (ga4EventName) {
							this._api.sendGoogleAnalyticsEvent(ga4EventName);
						}
					});
				if (false !== options?.chatRoomAction) { // Should we _also_ generate a ChatRoomActions record?
					notificationHandled = !!activity.triggerNotification;
					const action = this._getActionObject(message, actionOptions);
					this._api.sendAction(activity.actionName, action,
						!!activity.triggerNotification, options, this._pageClosing);
				}
			} else {
				notificationHandled = !!activity.triggerNotification;
				const action = this._getActionObject(message, actionOptions);
				this._api.sendAction(activity.actionName, action,
					!!activity.triggerNotification, options, this._pageClosing);
				this._api.sendGoogleAnalyticsEvent(activity.actionName, action);
			}
		}
		if (activity.directMessageCode && this._settings.Notifications?.Enabled) {
			this._api.sendDirectNotification(activity.directMessageCode, options);
		}
		if (activity.messageNotification && this._settings.Notifications?.Enabled) {
			const senderName = this._getUserContactInfo();
			this._api.sendMessageNotification(activity.options?.message /* the actual chat message being sent */,
				$.extend({}, options, { senderName: senderName }));
		}
		if (!notificationHandled && activity.notificationName && this._settings.Notifications?.Enabled) {
			this._api.sendNotification(activity.notificationName, message, options,
				this._pageClosing);
		}
		if (activity.isVisit) {
			if (this._settings.Notifications?.Enabled) {
				this._api.sendVisit(options.userAuthToken, this._getVisit(),
					this._leadModel?.leadContact?.email, this._session.identityId);
			}
			this._api.updateProjectViewerIdentity(
				window.page.preloadContent.content.ProjectId,
				this._session.identityId,
				this._roomId,
				window.page.preloadContent.content.CampaignId,
				this._leadModel?.leadContact?.email
			);
		}

		return this;
	}
}

export { ActivityMonitor }
